import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/81aa6c1fef84e7d5a5483f72a1ce7fbe/df56e/horus_2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACNUlEQVQ4y8VUS28SURhlqSb1V6mJXWoMGvckutDgxp2PpiaKC0jaJiYECFgXTknjAkk0TdoSoVJaSXCmPErFAsPAzPB+DMy99/sMDBKrUBc18Szu4ss595yTm++a8Aww/Rsx6AQBQBuMT0KRUOgPkAF0+2PCFDEAIjKpyhpt/eA7q7X1dF4XZSqqJFvsK/UB/w1aXVqqjsjTxLRSg65GDous2WF5GVs9KtdpTsJGj2aKrKsxpTHDmQErqazRZiUVAfqdbrkoMp1o9abP5pC+CKPYumEzI3a1pYsKIkYjOzeuXTcYV+fnV72+YTRySudKDToakYbFdj9Hr1y67HG5bpnNF+fm1tfW/iaWqkxt6qKKiMJX/sK58w+s1t1o1GKx+DyeU8UARFKh1x+IMiKGN7dum28aDNvzF+84PyLqmjajMyCW66xcg3IdEWt5sd1sQqs7aLQAgPUH43SM/S6G0eg4sFkryxsfPgaDQY7jQlvbbrfb5/Ouv+VKkpQUBK/b43Q6C4XCyA7GYkKHZRaeLS4tL9+7c/fVykqlUnl437rw6PFLm23xydPIp7Cf45YcDrvd7vcPK1BKTzjvhXcksRSLxbZDIbfLHQmHM+l06iC5t7//5vVq4H0gHo87nc5EIjHJb5pkqKpqKpXieT6TyaiycpTNCjx/dJgVRfE4l0umkoIgpNNpRVFOxDZAGaOMAQCl1LiYjkAIYT8nuq4bgf9YScDJlTDCePwLzrTPMOWd/8tP8gMenld8ZwSUgwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Refactoring",
            "title": "Refactoring",
            "src": "/static/81aa6c1fef84e7d5a5483f72a1ce7fbe/5a190/horus_2.png",
            "srcSet": ["/static/81aa6c1fef84e7d5a5483f72a1ce7fbe/772e8/horus_2.png 200w", "/static/81aa6c1fef84e7d5a5483f72a1ce7fbe/e17e5/horus_2.png 400w", "/static/81aa6c1fef84e7d5a5483f72a1ce7fbe/5a190/horus_2.png 800w", "/static/81aa6c1fef84e7d5a5483f72a1ce7fbe/df56e/horus_2.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/85afe9f3b02d7573a80ddc65bf208207/df56e/horus_3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACpUlEQVQ4y62T3UtTcRjHz2lCKqlL8AUvuoj+Ba+8Gg0lRZKUNoyKEXQlGCiZxaaTUpdOtxwmWWckDhOkLio3d6Ye3CKti7Wp27HNnW3nzUFQ7eXMue2c0LOlKBZhX56bh4fP8/0+8PsB3AkE/HmcPkYsy/4d/jfng4sZhpmZmZmYmJicnDQYDFNTU4Y96fV6t9t9rHM6neY4jiCIkpISAABAEASyEggEAACo1erDcCKRsNvtTqeTbymKKi8v55mKigqRSCQUCvlFWq12H+ZzhsPh6elpBEF+w6WlpTxcWVkpk8mKi4v5VqPRHICzxSu1F5ukqLKysr2op3hDEARzcnIOw/tiWY5l+ZtJMuN89Obh4eEMnGLZ7VQ6W6ntZJLZ2UlzXIAgLpw/l58rEBbmFRXkFhXkFZ45fbYoHwSA0VFdBsaj0QWSslL0EkVb6S0bSdpw/ANFL2ysa7olj9trBjrqBu5eUraKFS0Xla3VnberPtveZWB/JGIMBi04aQ7iJu/XOXR93o8tBHGzexUavPmsp2H8YaO+X/JcdUMrvzJ0v17VLnZ+MmXgQCQyh5OLdMiM+V4ZDa+RtwhOIAQJo6vjqutPu+p18to3UKfV9FKnlKraqx/dEX1ZNh5wxjAYw8yYzxLwWzY9sAe1YD6zywGpZS96JVB/s1517Ym8QatoGum+OtBR61gx7jvvwn6/GfPBmA/2oLAHnfftwvqhW1CfFFI1Q33Ska5GjaJRp5QM3qtzrGRjY+HIbBCHScoUCM5uuOY2XLAfm8cJk8s51ivVPajRyWvHlJdHupv62sT9beKelir7x/cZOJpM0tHYVozZYuKheDzEMKFoNBSL0T9/bLqXvatW75rNu2ZFHQjqWEIdVpd98fs3+n9/ySPv7Vid1PkXw4CaK48DdrUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Refactoring",
            "title": "Refactoring",
            "src": "/static/85afe9f3b02d7573a80ddc65bf208207/5a190/horus_3.png",
            "srcSet": ["/static/85afe9f3b02d7573a80ddc65bf208207/772e8/horus_3.png 200w", "/static/85afe9f3b02d7573a80ddc65bf208207/e17e5/horus_3.png 400w", "/static/85afe9f3b02d7573a80ddc65bf208207/5a190/horus_3.png 800w", "/static/85afe9f3b02d7573a80ddc65bf208207/df56e/horus_3.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ec3aea5849f37e48ce112e96d66d569b/df56e/horus_4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAEFklEQVQ4y03SW2/TZhjAcX+ZCdjF7qdpE5XQdtldTDsItlZsFZ0QjEOnspUBhY71lDYpaeLmjHOoc7RfO44PiRM7iePYsf3a6fmQtrSMim8xpd1g0nP70//RoweZJgozJDELyDkAXDS1WKQ9DLNUKi3zPFopB6q1sCTH6o240kqo7bSm5wyDsCDtrLEb68g77KLAIv1e+stCoCaFarWoJGNNJam28XMJIe10mbU1fnMLmQVkX9LUuXzBsl6W9VeqPjLty0S9UVeYBXFVS6ntjK7nTZOE9ns8R4F5mlr4T/abghCQ6iiR8gb/9MfdUQ6kOhBvt3OdTsGygG3T3T5mNzaQeZpyFWl3sbhUKnk5zicIKM8GOCbAFMIsFRV5rFpOVIWM3s6bBgHPsNOlbQfXtH+xp1TqZzkOrVRQKruMefx4AE0uBzPBSD4aTC6lFZmwbQJaJIQA2tmOgTWayBwNFvqY8XKsXxBWKuVgRQiV2WiF6zdlMSWLuCLnDf1MWue+YJoFy0RmAFhgiu53F66Uw7VarC4nlGaq1cLbarbTyZsmYVmk1ccktCjbBtAGto08y6Sn0vHFIrFUotGyEBLFqFSLN+pJpbmqtjLa2Z0MgzzDoL8zpB2HdhwAIXLPP/97YO7W1Mij4KSPLwUr/EtZTjQbqZbSz+p6vtMhTPOdBBAWu13acSjHRr4dv/EA9fx881NXZMLPsyGxgtXlZLOx2lL6WV0vGMb/MXVWpmw7bxjI1+N3xvwzT10jARDxsMVgtRqr1+OKklJbeLv/GDnDKJgWYcH3A2FW17G6hNxe9k7EsbLEc4qMCRyuKUlFwmQx1ZTTajOtNrJaK6+rhY5GGBppaIVOmzS0nNZONuvILe8SFpsl/Q+KocfMysOxka8wdNpqMaqYUyqZdVjfsBubjrLVbW2fzc6GdrDdUQwpVOGR7x6Ohzy/Rp7+hD65jj4amv7lGpUKH+xvr1m6WmV7O5sHu5uHu1tH+zuv9nePD/aOD3unr3qqLq7wRWTwzs2X/glqeSznHUtM33jx7B4Lsm9PT3o767At/X3ce3N88Obk8PTk6PTk6PXx0etXh2+Pe2qn5itRyJejw67ntzH3Xcx9NzE3OnX/WnDxD4CjWcydQJ8XkstEykeu+gGOglVfsxhRqjnYKvHlrDsdQ+5dvXL/2pXRwY9/G/p88scvvvnkwvCVj65+duH7y5d+GPhwaODS8MDFocsXhgcuXr/8gX9skH85KeU8TeAT8XmEjkxy2HMm/EyI/8VFngDfg+LKBB+bYkKP2fBkDV+s4Ytial5MzIjJ2RbxYkNO75vlni3umQJiiKvdJmlLOCzH1htpW8TsWmoPCt16Zr2R3daZHY3aNdh9S9i3uAOnuqMzuwbb68p7jvQPxTY2V77p94AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Refactoring",
            "title": "Refactoring",
            "src": "/static/ec3aea5849f37e48ce112e96d66d569b/5a190/horus_4.png",
            "srcSet": ["/static/ec3aea5849f37e48ce112e96d66d569b/772e8/horus_4.png 200w", "/static/ec3aea5849f37e48ce112e96d66d569b/e17e5/horus_4.png 400w", "/static/ec3aea5849f37e48ce112e96d66d569b/5a190/horus_4.png 800w", "/static/ec3aea5849f37e48ce112e96d66d569b/df56e/horus_4.png 1188w"],
            "sizes": "(max-width: 800px) 100vw, 800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      